<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Categorias - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'store-gift-list-categories'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-store-gift-list-category :storegiftlistcategory="storegiftlistcategory" :update="true"></form-store-gift-list-category>
</span>
</template>

<script>
import FormStoreGiftListCategoryComponent from "./partial/FormStoreGiftListCategoryComponent";

export default {
    name: "AddStoreGiftListCategoryComponent",
    created() {
        this.loadStoreGiftListCategory()
    },
    props: {
        stor_glc_id: {
            require: true
        }
    },
    data() {
        return {
            storegiftlistcategory: {
                stor_glc_id: '',
                product_category_id: '',
                stor_glc_nome: '',
                stor_glc_slug: '',
                stor_glc_descricao: '',
                stor_glc_logo: '',
                stor_glc_banner: '',
                stor_glc_status: true,
                stor_glc_show_menu: true,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadStoreGiftListCategory() {
            this.$store.dispatch('loadStoreGiftListCategory', this.stor_glc_id)
                .then(response => this.storegiftlistcategory = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formStoreGiftListCategory: FormStoreGiftListCategoryComponent
    }
};
</script>

<style scoped>

</style>
